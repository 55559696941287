import { render, staticRenderFns } from "./DemoComp.vue?vue&type=template&id=ba0f1dc2&scoped=true"
import script from "./DemoComp.vue?vue&type=script&lang=js"
export * from "./DemoComp.vue?vue&type=script&lang=js"
import style0 from "./DemoComp.vue?vue&type=style&index=0&id=ba0f1dc2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba0f1dc2",
  null
  
)

export default component.exports