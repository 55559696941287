var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h5 px-0"},[_vm._v(" "+_vm._s(_vm.displayName(_vm.intent.intent, _vm.user.language))+" "),(_vm.isOriginIntent(_vm.intent.intent))?_c('ChipIcon',{staticClass:"ml-2",attrs:{"icon":"model_training","color":"secondary darken-2","text":_vm.$t('intent.aiTemplate.label')}}):_vm._e()],1),_c('div',{staticClass:"pb-6"},[_c('p',{staticClass:"body-text-1 mb-0"},[_vm._v(" "+_vm._s(_vm.description(_vm.intent.intent, _vm.user.language))+" ")]),(_vm.isAutoTranslated(_vm.intent.intent, _vm.user.language))?_c('div',{staticClass:"text-body-2 grey--text mt-2"},[_vm._v(" "+_vm._s(_vm.$t('common.autoTranslated'))+" ")]):_vm._e()]),_c('div',[_c('IntentTags',{staticClass:"mb-4",attrs:{"intent":_vm.intent}}),(_vm.intent.suggested)?_c('IntentStatusSuggested',{staticClass:"mb-4",attrs:{"intent":_vm.intent,"status":_vm.status}}):_c('IntentStatus',{staticClass:"mb-4",attrs:{"intent":_vm.intent}}),_c('IntentSamples',{staticClass:"mb-4",attrs:{"intent":_vm.intent}}),(!_vm.inReview && _vm.intent.template !== 'Smalltalks' && _vm.intent.suggested !== true)?_c('IntentRelevance',{staticClass:"mb-4",attrs:{"channel":null,"intent":_vm.intent.intent,"bot-id":_vm.currentBotId}}):_vm._e(),(!_vm.inReview && !_vm.noMeasurement && !_vm.isConversion)?_c('IntentFeedback',{staticClass:"mb-4",attrs:{"channel":null,"intent":_vm.intent.intent,"bot-id":_vm.currentBotId}}):_vm._e(),(!_vm.noMeasurement && _vm.isConversion && false)?_c('IntentConversion',{staticClass:"mb-4",attrs:{"channel":_vm.currentChannels[0].channel,"intent":_vm.intent.intent,"bot-id":_vm.currentBotId}}):_vm._e(),(_vm.isAdmin && !_vm.noMeasurement)?_c('IntentAccuracy',{staticClass:"mb-4",attrs:{"channel":null,"intent":_vm.intent.intent,"bot-id":_vm.currentBotId}}):_vm._e(),(
          !_vm.intent.intent.startsWith('smalltalk_') &&
          !_vm.isOriginIntent(_vm.intent.intent) &&
          !_vm.isOriginIntent(_vm.intent.intent, 'smalltalks') &&
          _vm.hasKeywordMatchFeature
        )?_c('IntentKeywords',{staticClass:"mb-4",attrs:{"intent":_vm.intent,"botId":_vm.currentBotId}}):_vm._e(),_c('IntentGeneral',{staticClass:"mb-4",attrs:{"intent":_vm.intent}}),(_vm.isAdmin)?_c('IntentRelations',{staticClass:"mb-4",attrs:{"intentId":_vm.intent.intent}}):_vm._e(),(_vm.isAdmin)?_c('IntentK2Names',{staticClass:"mb-4",attrs:{"intent":_vm.intent}}):_vm._e(),((_vm.isAdmin || (!_vm.hasLegacyModel && _vm.isOwner)) && _vm.intent.template !== 'Smalltalks')?_c('div',[_c('v-divider',{staticClass:"my-8"}),(!_vm.intent.suggested)?_c('v-btn',{attrs:{"color":!_vm.hasLegacyModel ? 'error lighten-1' : 'admin lighten-1',"depressed":""},on:{"click":function($event){_vm.deleteMode = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]):(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"admin lighten-1","depressed":""},on:{"click":function($event){_vm.deleteMode = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]):_vm._e(),(_vm.inReview && _vm.isAdmin)?_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"admin lighten-1"},on:{"click":function($event){_vm.isApproveOpen = true;
            _vm.approveCheck();}}},[_vm._v(" Approval ")]):_vm._e(),(_vm.isAdmin)?_c('IntentReviewAssistant',{attrs:{"open":_vm.isApproveOpen,"intent":_vm.intent},on:{"onClose":function($event){_vm.isApproveOpen = false},"onApprove":_vm.approve}}):_vm._e()],1):_vm._e()],1),_c('HubDialog',{attrs:{"title":_vm.$t('intent.deleteHeadline'),"primary-icon":"delete","primary-label":_vm.$t('common.delete'),"primaryColor":"error lighten-1"},on:{"onPrimary":_vm.deleteIntent},model:{value:(_vm.deleteMode),callback:function ($$v) {_vm.deleteMode=$$v},expression:"deleteMode"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('intent.deleteConfirmation', { name: _vm.intent.displayName }))}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }